@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
       url('./assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: 'Gilroy', sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: white;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}

.w-min-content {
  width: min-content;
}

/* Start swiper style */

.swiper {
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}

.swiper-slide {
  font-size: 18px;
  margin-right: 15px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  background-color: #43766c;
}

.swiper-button-next,
.swiper-button-prev {
  color: #43766c;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: 'Gilroy',"Barlow", sans-serif;
  font-size: 20px;
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 50%;
}

.swiper-button-next:after {
  margin-right: 15px;
}

/* End swiper style */

/* start Loading Style */

.loading::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -10%;
  width: 150px;
  height: 150px;
  background-color: #356df6;
  border-radius: 50%;
  filter: blur(12rem);
  z-index: -999;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-filter: blur(12rem);
}

.loading div h1 {
  text-align: left;
  font-weight: 900;
  color: #597e52;
}

.loading div h2 {
  text-align: left;
  font-weight: 900;
  color: #e3f7f0;
}

.loading div button {
  font-family: "Barlow", sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: #43766c;
}

.loading div button:hover {
  font-family: "Barlow", sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: black;
}

.loading .features {
  width: inherit;
}

.loading div p {
  font-weight: 600;
}

.loading div .cards {
  position: relative;
  left: calc(55% - 60px);
  transform: translateX(-50%);
  width: fit-content;
  display: flex;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.imageHeader {
  height: 100%;
  width: 100%;
  /* background-color: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
}

/* home start */

.home {
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #bddcfa;
}

.icon-ill-01 {
  animation: upAndDown 3s infinite;
  margin-left: 20px;
  margin-top: 20px;
  width: 30%;
  height: 30%;
}
.icon-ill-02 {
  animation: upAndDown 10s infinite;
  width: 25%;
  height: 25%;
}
.icon-ill-03 {
  animation: upAndDown 3s infinite;
  width: 30%;
}
.icon-ill-04 {
  animation: rotateImage 10s infinite;
  width: 25%;
  height: 25%;
}

.btn-activation {
  background-color: #fd0100;
  border: 1px solid #fd0100;
  border-radius: 5px;
  /* width: 40%; */
  margin-right: 1%;
}

.btn-activation-card {
  background-color: #fd0100;
  border: 1px solid #fd0100;
  border-radius: 5px;
  /* width: 40%; */
  margin-right: 1%;
  padding: 5px 5px;
}

.btn-get-kartu-keren {
  background-color: #fd0100;
  border: 1px solid #fd0100;
  border-radius: 5px;
  /* width: 90%; */
  margin-bottom: 2em;
}

.btn-check-balance {
  background-color: #fd0100;
  border: 1px solid #fd0100;
  border-radius: 5px;
  /* width: 70%; */
}

.tx-desc-kartu-keren {
  color: black;
  font-weight: 200;
  font-size: 1em;
}

.input-search {
  background-color: white;
  border-radius: 5px;
  padding: 5px 5px;
}

.input-search-card {
  background-color: white;
  border: 0.2px solid rgb(207, 207, 207);
  border-radius: 5px;
}

.tx-red-bold {
  color: #fd0100;
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 0.7em;
}

.tx-red-bold-small {
  color: #fd0100;
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0.7em;
}

.tx-lokasi-pengambilan {
  color: red;
  font-size: 0.6em;
  font-weight: 700;
}

.tx-product-name {
  font-size: 0.9em;
  margin-bottom: 1px;
  font-weight: 300;
}
.tx-product-desc {
  font-size: 0.6em;
  font-weight: 200;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tx-product-cross-price {
  font-size: 0.7em;
  font-weight: 200;
  margin-bottom: 1px;
  text-decoration: line-through;
  color: #fd0100;
}
.tx-product-price {
  font-size: 0.8em;
  font-weight: 400;
  color: #000000;
}

.tx-pagination-total {
  font-size: 0.8em;
  font-weight: 300;
}

.btn-add {
  border: 1px solid #fd0100;
  width: fit-content;
}

.custom-button {
  padding: 5px 15px;
  font-size: 12px;
  color: red;
  background-color: white;
  border: 0.7px solid red;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: box-shadow 0.3s ease;
}

.img-product {
  filter: grayscale(100%);
}

.custom-button:disabled {
  color: gray;
  border: 0.7px solid gray;
  background-color: white;
}

.custom-button:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.card-product {
  padding: 10px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.btn-goto-wa {
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: transparent;
  border: 0px;
  right: 0;
  background-image: url("./images/wa-btn.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90px;
  height: 48px;
  margin-bottom: 2rem;
  margin-right: 2rem;
  align-content: end;
}

.box-merchant {
  background-color: white;
  border-radius: 5px;
  padding-inline: 15px;
  padding-block: 10px;
}

.tx-merchant-name {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 1px;
}

.tx-merchant-address {
  font-weight: 200;
  font-size: 0.8em;
}

.tx-card-number {
  font-size: 1em;
}

.tx-card-balance {
  font-size: 0.9em;
  margin-bottom: 2px;
}

.tx-card-balance-number {
  font-size: 1.4em;
  font-weight: 600;
}

/* home end */

/* start modal card detail */

.col-card {
  width: 100%;
  height: 15rem;
  padding-top: 20%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  padding-left: 20px;
  border-radius: 10px;
  background-image: url("./images/card-frame.png");
  background-size: 100%, 20rem;
  background-position: center;
  background-repeat: no-repeat;
}

/* end modal card detail */
#container-header {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.loading div .cards .card {
  width: fit-content;
}

.loading div .cards .card:first-child {
  left: -25px;
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
}

.loading div .cards .card:last-child {
  position: absolute;
  top: 25px;
  right: -120px;
  box-shadow: -3px -4px 11px 5px #00000059;
  transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
}

.loading div .cards .card .card-body img {
  max-width: 190px;
}

/* End loading style */

/* start not found */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  color: #24b0a2;
}

.not-found-text {
  font-size: 1.5rem;
  color: #2d3436;
  margin: 1rem 0;
}

.not-found-button {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: #24b0a2;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #24b0a2;
}

.not-found-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin-top: 2rem;
}

.not-found-cloud {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: moveClouds 15s linear infinite;
}

.not-found-cloud:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50%;
  animation-duration: 18s;
}

@keyframes moveClouds {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* Start media query */

/* Small */
@media (max-width: 768px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  .loading .img-logo {
    margin-bottom: 45%;
  }
  #container-header {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* medium */

@media (min-width: 768px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  .loading .img-logo {
    margin-bottom: 30%;
  }
  #container-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@media (max-width: 992px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 750px;
  }
  #container-header {
    position: absolute;
    top: 45%;
    left: 50%;
    color: #fff;
  }
}

/* Large */

@media (min-width: 1198px) {
  .contact {
    width: 90%;
  }
  .imageHeader {
    object-fit: cover;
    height: 910px;
  }
  .loading .img-logo {
    margin-bottom: 35%;
  }
  #container-header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* End media query */
